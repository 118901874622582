import React from "react";

import BrandsBlock from "./brandsBlock";
import FeaturedUpdates from "./featuredUpdates";
import FeaturedWork from "./featuredWork";
import OfferingsBlock from "./offeringsBlock";
import Testimonials from "./testimonials";
import { Maybe, SanityHomePage } from "@graphql-types";

interface Props {
  data: Maybe<SanityHomePage> | undefined;
}

export function Static({ data }: Props) {
  return (
    <>
      <OfferingsBlock data={data?.offeringsBlock} />
      <BrandsBlock
        brands={data?.featuredBrands?.socialLinks}
        title={data?.brandsTitle}
        description={data?.brandsDescription}
      />
      <FeaturedWork
        works={data?.featuredWorks}
        title={data?.workTitle}
        description={data?.workDescription}
      />
      <Testimonials
        title={data?.testimonialsTitle}
        description={data?.testimonialsDescription}
        testimonials={data?.testimonialsCollection}
      />
      <FeaturedUpdates updates={data?.featuredUpdates} />
    </>
  );
}
