import React from "react";
import useScrollProgress from "../../hooks/useScrollProgress";
import styled from "styled-components";
import {
  IPAD_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  MOBILE_BREAKPOINT,
  SMALL_LAPTOP_BREAKPOINT,
  TABLET_BREAKPOINT,
  assets,
  colors,
} from "@util/constants";

import { ContentContainer } from "../home/homeHero.styles";
import { RichTextContent } from "./sub";
import { Maybe, SanityLink, SanityRichTextBlock } from "@graphql-types";

interface Props {
  heroTitle: SanityRichTextBlock;
  heroExcerpt?: Maybe<string> | undefined;
  heroCtas?: Maybe<Maybe<SanityLink>[]> | undefined;
}

export default function NewHero({ heroTitle, heroExcerpt }: Props) {
  const scrollProgress = useScrollProgress();

  return (
    <ScrollContainer>
      <FixedBackground
        style={{ backgroundPosition: `${scrollProgress}% ${scrollProgress}% ` }}
      />
      <div className="content">
        <StyledRichTextContent width="100%">
          <RichTextContent
            isH1
            blocks={heroTitle?.richTextContent}
            color="white"
            excerpt={<p className="excerpt desktop">{heroExcerpt}</p>}
            floatingAnimation={
              <RotatingText
                className="desktop"
                src={assets.motto}
                alt="motto"
              />
            }
          />
          <span className="excerpt-wrapper mobile">
            <p className="excerpt">{heroExcerpt}</p>
            <RotatingText
              className="rotating-text"
              src={assets.motto}
              alt="motto"
            />
          </span>
        </StyledRichTextContent>
      </div>
    </ScrollContainer>
  );
}

const StyledRichTextContent = styled(ContentContainer)`
  #rich-text-0,
  #rich-text-3 {
    display: flex;
    justify-content: center;
    position: relative;
  }
`;

const FixedBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  background-color: ${colors.black};
  background-image: url("/flightbg.webp");
  background-repeat: no-repeat;
  z-index: -2;
  transform: scale(1.5);
  will-change: background-position;
  transition: background-position 0.5s;
`;

const ScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
  height: 85dvh;
  min-height: 900px;
  overflow: hidden;

  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    min-height: 0px;
  }

  .content {
    width: 90%;
    margin: auto;
    text-align: center;
    justify-content: center;
    position: relative;
  }

  .mobile {
    display: none;
    @media only screen and (max-width: 876px) {
      display: block;
    }
  }

  .desktop {
    display: block;
    @media only screen and (max-width: 876px) {
      display: none;
    }
  }

  .excerpt {
    margin-left: 40px;
    color: white;
    max-width: 250px;
    text-align: left;
    font-size: 20px;
    line-height: 1.2;

    @media only screen and (max-width: 1175px) {
      position: relative;
      top: unset;
      right: unset;
      font-size: 16px;
    }

    @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      max-width: 200px;
    }
  }

  .richTextSpan {
    font-size: clamp(50px, 8vw, 130px) !important;
    line-height: clamp(50px, 10vw, 130px) !important;
  }

  .excerpt-wrapper {
    display: none;
    justify-content: space-between;

    @media only screen and (max-width: 876px) {
      display: flex;
    }

    .rotating-text {
      position: relative;
    }

    .excerpt {
      margin: 0;
    }
  }
`;

const RotatingText = styled.img`
  width: 150px;
  height: 150px;
  position: absolute;
  top: 25%;
  right: 0px;

  -webkit-animation: spin 15s linear infinite;
  animation: spin 15s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100px;
    height: 100px;
    right: 0;
  }
`;
