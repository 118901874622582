import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@components/shared/seo";
import { BlocksContent, Modal } from "@sub";
import { TABLET_BREAKPOINT } from "@util/constants";
import { Container } from "@util/standard";
import { Static } from "@components/home/static";
import { Blocks } from "@components/shared/blocks";
import NewHero from "@components/shared/newHero";

interface Props extends PageProps {
  data: Query;
}

export default function Home({ data }: Props) {
  const [popupOpen, setPopupOpen] = useState(false);
  const { sanityHomePage } = data;

  if (!sanityHomePage) return null;
  const {
    popup,
    seo,
    useModularBlocks,
    blocks,
    heroTitle,
    heroExcerpt,
    heroCtas,
  } = sanityHomePage;

  return (
    <StyledWrapper>
      <SEO seoData={seo} slug="/" />
      {popup && (
        <PopUpWrapper>
          <Modal
            height="max-content"
            visible={popupOpen}
            onClose={() => setPopupOpen(false)}
          >
            <Container flexDirection="column" className="pop-up-content">
              <Container width="100%" tabletWidth="100%">
                <BlocksContent blocks={popup.columnOne?._rawColumnContent} />
              </Container>
              <Container width="100%" tabletWidth="100%">
                <BlocksContent blocks={popup.columnTwo?._rawColumnContent} />
              </Container>
            </Container>
          </Modal>
        </PopUpWrapper>
      )}
      <NewHero
        heroTitle={heroTitle}
        heroExcerpt={heroExcerpt}
        heroCtas={heroCtas}
      />
      <Container maxWidth="95%" width="95%">
        {useModularBlocks ? (
          <Blocks isHome data={blocks as any[]} />
        ) : (
          <Static data={sanityHomePage} />
        )}
      </Container>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  .heading-1 {
    font-size: 80px;
    max-width: 77%;
    margin: 0;
    display: block;
    line-height: 100%;
  }

  .heading-3 {
    max-width: 77%;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .heading-1 {
      max-width: 100%;
    }
    .heading-3 {
      max-width: 100%;
    }
`;

export const query = graphql`
  query HomeQuery {
    sanityHomePage {
      useModularBlocks
      seo {
        ...sanitySeo
      }
      popup {
        ...sanityPagePopup
      }
      blocks {
        ...sanityPageBlocks
      }
      offeringsBlock {
        ...sanityOfferingsBlock
      }
      heroTitle {
        richTextContent {
          _key
          style
          children {
            text
            marks
          }
        }
      }
      heroExcerpt
      heroCtas {
        _key
        internallink
        linktext
        newwindow
        url
      }
      vimeoURL
      mobileHeroImage {
        asset {
          fluid {
            srcWebp
            srcSetWebp
            ...GatsbySanityImageFluid
          }
        }
      }

      featuredBrands {
        socialLinks {
          socialIcon {
            asset {
              fluid {
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
              _id
            }
          }
        }
      }
      brandsTitle
      brandsDescription
      featuredWorks {
        ...sanityWork
      }
      workDescription
      workTitle
      testimonialsDescription
      testimonialsTitle
      testimonialsCollection {
        text
        from
        featuredImage {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
        brandIcon {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
        _key
      }
      featuredUpdates {
        _id
        category {
          title
        }
        image {
          asset {
            fluid {
              srcWebp
              srcSetWebp
              ...GatsbySanityImageFluid
            }
          }
        }
        mobileImage {
          ...sanityImageWithMeta
        }
        title
        slug {
          current
        }
      }
    }
  }
`;

const PopUpWrapper = styled.div`
  .rodal-dialog {
    border-radius: 20px;
    width: 40% !important;
  }

  .pop-up-content {
    text-align: center;
    span {
      display: block;
      width: max-content;
      margin: auto;
    }

    margin: 20px auto;
    height: 100%;
    width: 90%;
    align-items: center;
    justify-content: space-between;
  }

  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    .rodal-dialog {
      min-height: 50vh;
      display: flex !important;
      width: 80% !important;
    }

    .pop-up-content {
      display: block;
      margin: auto;
      height: max-content;
    }
  }
`;
