import { useEffect, useState } from "react";
import { isBrowser, throttle } from "@util/helper";

const useScrollProgress = () => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!isBrowser()) return;
    const handleScroll = throttle(() => {
      const totalScroll = document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const docHeight = document.documentElement.offsetHeight;

      const totalDocScrollLength = docHeight - windowHeight;
      const scrollPosition = Math.floor((totalScroll / totalDocScrollLength) * 250);

      setProgress(scrollPosition);
    }, 100); // Throttle scroll events to fire at most once every 100ms

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  return progress;
};

export default useScrollProgress;
